import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import DataTableComponent from '../DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { defaultUserType, defaultMonitoringColumnName } from '../../api/helper';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import MonthlyMonitoring from '../MonthlyMonitoring';
import PlasticMonitoring from '../PlasticMonitoring';
import { PROJECT_DETAILS } from '../../Constant';
import MonitoringDocUpload from '../MonitoringDocUpload/MonitoringDocUpload';
import MonthlyBiocharMonitoring from '../BiocharMonitoring/MonthlyBioCharMonitoring';
import DailyHydroMonitoring from '../HydroMonitoring/DailyHydroMonitoring';
import moment from 'moment';

const Monitoring = () => {
    const projectId = useParams()?.id;
    const projectName = useParams()?.name;
    const userType = useSelector(state => state.user?.userType);
    const dispatch = useDispatch();
    const type = useParams()?.type;
    const frequency = useParams()?.frequency;
    const [year, setYear] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const navigate = useNavigate();
    const [vendorId, setVendorId] = useState();
    const [vendorList, setVendorList] = useState([]);
    const [rowData, setRowData] = useState();
    const [evidenceUploadOpen, setEvidenceUploadOpen] = useState(false);
    const [vendor, setVendor] = useState();

    const getAllVendors = async () => {
        await fetchWrapper(`${API_URL}/vendor/project/${projectId}`)
            .then((res) => {
                console.log(res.json)
                setVendorList(res.json);
            }, (err) => {
                console.log(err)
            })
    }

    const projectType = {
        'plastic': 'Plastic Waste Collection (Metric tons)',
        'bms': 'Energy Consumption',
        'solar': 'Energy Generation',
        'lighting': 'Energy Consumption',
        'biochar': 'Energy Consumption',
        'water': 'Water Consumption',
        'hydro': 'Energy Consumption'
    }


    const loadProjectData = async () => {
        await fetchWrapper(`${API_URL}/projects/${projectId}`)
            .then((res) => {
                dispatch({
                    type: PROJECT_DETAILS,
                    payload: res.json
                })
            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (!frequency) {
            loadProjectData()
        }
        getAllVendors()
    }, [])

    const handleVendor = (id) => {
        setVendorId(id)
        const getVendor = vendorList?.find(ele => ele?.id === id);
        setVendor(getVendor?.name)
    }

    useEffect(() => {
        console.log(year)
    }, [year])

    return (
        <Fragment>
            <Container fluid>
                <Breadcrumbs parent="EV Charges List" title="List" mainTitle={`${projectName}`} />
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <H3 attrH3={{ className: 'title-color' }}>{projectType[type]}</H3>
                            <div className="d-flex">
                                <Row>
                                    <Col lg={(frequency === 'DAILY') ? '3' : type === 'biochar' ? '6' : '4'}>
                                        <FormGroup>
                                            <Label style={{ display: 'block' }}>Data Collection Frequency</Label>
                                            <select className='form-select' value={frequency}
                                                disabled={true}
                                                {...register('reportingPeriodFrequency', { required: true })}
                                            >
                                                <option value=''>Select Period</option>
                                                <option value={'DAILY'}>Daily</option>
                                                <option value={'MONTHLY'}>Month</option>
                                                <option value={'QUARTERLY'}>Quarter</option>
                                                <option value={'HALFYEARLY'}>Semi Annual</option>
                                                <option value={'YEARLY'}>Annual</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={(frequency === 'DAILY') ? '3' : '4'}>
                                        <FormGroup>
                                            <Label>Select Year</Label>
                                            <DatePicker
                                                className="datepicker-here form-control"
                                                selected={year}
                                                onChange={(e) => setYear(e)}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                maxDate={new Date()}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {
                                        (frequency === 'DAILY') && <Col lg='3'>
                                            <FormGroup>
                                                <Label>Select Month</Label>
                                                <DatePicker
                                                    className="datepicker-here form-control"
                                                    selected={month ? month : new Date()}
                                                    onChange={(e) => setMonth(e)}
                                                    showMonthYearPicker
                                                    dateFormat="MMM"
                                                    placeholderText="-Month-"
                                                    maxDate={new Date()}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                    {
                                        type !== 'biochar' && vendorList?.filter(ele => (ele?.status === 'ACTIVE') && (ele?.name !== 'Self'))?.length > 0 && <Col lg={(frequency === 'DAILY') ? '3' : '4'}>
                                            <FormGroup>
                                                <Label>Vendor</Label>
                                                <select className='form-select' name='vendor' value={vendorId}
                                                    onChange={(e) => handleVendor(e.target.value)}>
                                                    <option value={''}>-Select vendor-</option>
                                                    {
                                                        vendorList?.filter(ele => (ele?.status === 'ACTIVE') && (ele?.name !== 'Self'))?.map((item) => (
                                                            <option value={item?.id}>{item?.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                            </div>

                            {
                                (type === 'solar') && <MonthlyMonitoring
                                    projectId={projectId}
                                    frequency={'MONTHLY'}
                                    year={year}
                                    userType={userType}
                                    columnType={defaultMonitoringColumnName[type]}
                                    projectName={projectName}
                                    type={type}
                                    vendor={vendorId}
                                    setRowData={setRowData}
                                    setEvidenceUploadOpen={setEvidenceUploadOpen}
                                />
                            }
                            {
                                (type === 'plastic') && <PlasticMonitoring
                                    projectId={projectId}
                                    vendor={vendorId}
                                    frequency={frequency}
                                    year={year}
                                    month={month}
                                    userType={userType}
                                    projectName={projectName}
                                    type={type}
                                    setRowData={setRowData}
                                    setEvidenceUploadOpen={setEvidenceUploadOpen}
                                />
                            }
                            {
                                (type === 'biochar') && <MonthlyBiocharMonitoring
                                    projectId={projectId}
                                    vendor={vendorId}
                                    frequency={frequency}
                                    year={year}
                                    month={month}
                                    userType={userType}
                                    projectName={projectName}
                                    type={type}
                                    setRowData={setRowData}
                                    setEvidenceUploadOpen={setEvidenceUploadOpen}
                                />
                            }
                            {
                                (type === 'hydro') && <DailyHydroMonitoring
                                    projectId={projectId}
                                    vendor={vendorId}
                                    frequency={frequency}
                                    year={year}
                                    month={month}
                                    userType={userType}
                                    projectName={projectName}
                                    type={type}
                                    setRowData={setRowData}
                                    setEvidenceUploadOpen={setEvidenceUploadOpen}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Container>
            {
                evidenceUploadOpen && <MonitoringDocUpload
                    value={evidenceUploadOpen}
                    category={type}
                    setOpenModal={setEvidenceUploadOpen}
                    data={rowData}
                    projectId={projectId}
                    vendorId={vendorId}
                    vendorName={vendor}
                    frequencyValue={frequency}
                    month={month}
                    year={moment(year).format('YYYY')}
                />
            }
        </Fragment>
    )
}

export default Monitoring;