import React, { Fragment, useState, forwardRef, useRef, useEffect } from "react";
import { Card, Row, Col, FormGroup, Label, Button, CardBody, Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import { H3, Breadcrumbs } from "../../../AbstractElements";
import { Download, X, Trash2 } from "react-feather";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import moment from "moment";
import pdfImg from '../../../assets/images/document/pdf.png'
import { useNavigate, useParams } from "react-router";
import LayoutLoader from "../LayoutLoader/LayoutLoader";
import AgGridTable from "../AgGridTable";
import { defaultUserType } from "../../api/helper";
import Swal from "sweetalert2";


const MonitoringDocUpload = ({ value, setOpenModal, data, projectId, category, vendorId, vendorName, 
    month, year, frequencyValue }) => {
    const [open, setOpen] = useState(value)
    const gridRef = useRef(null);
    const [tableData, setTableData] = useState([]);
    const [historyState, setHistoryState] = useState(0);
    
    const onClose = () => {
        setOpen(false);
        setOpenModal(false);
    }

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const loadDocumentData = async () => {
        let period = data?.frequency === 'DAILY' ? data?.date : data?.period ? data?.period : data?.reportingPeriodFrequencyValue;
        let frequency = data?.frequency ? data?.frequency : frequencyValue;
        let targetUrl;
        if(category === 'ev') {
            targetUrl = `getEVDocuments?projectId=${projectId}&category=${category?.toUpperCase()}&frequency=${frequency}&year=${year}&month=${month}&vendorId=${vendorId ? vendorId : data?.vendorId ? data?.vendorId : ''}&chargerId=${!data?.isDataSave ? data?.id : data?.chargerId}&stationId=${data?.stationId}`
        } else {
            targetUrl = `getDocuments?projectId=${projectId}&category=${category?.toUpperCase()}&frequency=${frequency}&year=${data?.year ? data?.year : year}&period=${period}&vendorId=${vendorId ? vendorId : data?.vendorId ? data?.vendorId : ''}`
        }
        await fetchWrapper(`${API_URL}/document/${targetUrl}`)
            .then((res) => {
                setTableData(res?.json)
            }, (err) => { console.log(err) })
    }

    const downloadEvidence = async (paramsData) => {
        await fetchWrapper(`${API_URL}/document/downloadCollection/${paramsData?.id}`)
            .then((err) => {
                console.log(err.json)
                if (err.status === 200) {
                    const link = document.createElement('a');
                    link.href = err?.json?.presignedUrl;
                    link.download = paramsData.uploadedFileName;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
            .catch((err) => {
                console.log(err, 'asdasd')
            })
    }

    const deleteEvidence = async (paramsData) => {
        const options = {
            method: 'DELETE'
        }
        await fetchWrapper(`${API_URL}/document/deleteCollection/${paramsData?.id}`, options)
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Document deleted successfully!')
                }
            }, (err) => {
                if (err?.status === 200) {
                    toast.error('Document deleted successfully!')
                    loadDocumentData()
                }
                if (err.status === 500) {
                    toast.error('Something went wrong!')
                }
            })
    }

    const ActionItems = (params) => {
        const options = [
            { value: 1, label: 'Download' },
            { value: 2, label: 'Delete' }
        ];
        return (
            <div className="d-flex justify-center">
                <div onClick={() => downloadEvidence(params.data)}>
                    <Download className='document-icon' />
                </div>
                <div className='ml-1-5' onClick={() => deleteEvidence(params.data)}>
                    <Trash2 className={`trash-icon`} />
                </div>
            </div>
        )
    }

    const columnsData = [
        {
            headerName: 'Invoice/Voucher',
            field: 'originalFileName',
            filter: true,
            sortable: true,
            resizable: false,
            flex: 2,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Action',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.isDelete === true ? 'Deleted' : 'Uploaded'
            }
        },
        {
            headerName: 'Actioned By',
            field: 'userName',
            filter: false,
            sortable: true,
            resizable: false,
            flex: 1,
            suppressMovable: true
        },
        {
            headerName: 'Actioned Time (UTC)',
            field: 'deletedTime',
            filter: false,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            valueGetter: (params) => {
                return params.data.lastModifiedTs ? moment(params.data.lastModifiedTs).format('DD MMM, YYYY hh:MM A') : ''
            }
        },
        {
            headerName: '',
            width: 100,
            cellRendererSelector: params => {
                return {
                    component: ActionItems
                }
            }
        }
    ];

    useEffect(() => {
        loadDocumentData()
        console.log(data)
    }, [])



    const savingFile = async (tempData, allFiles) => {
        const authToken = sessionStorage.getItem('accessToken')
        console.log(data, frequencyValue)
        let period = data?.frequency === 'DAILY' ? data?.date : data?.period ? data?.period : data?.reportingPeriodFrequencyValue;
        let frequency = data?.frequency ? data?.frequency : frequencyValue;
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${authToken}`
            },
            body: tempData
        }
        let targetUrl;
        if(category === 'ev') {
            targetUrl = `uploadEVCollection?frequency=${frequency}&year=${year}&month=${month}&projectId=${projectId}&category=${category?.toUpperCase()}&vendorId=${vendorId ? vendorId : data?.vendorId ? data.vendorId : ''}&chargerId=${!data?.isDataSave ? data?.id : data?.chargerId}&stationId=${data?.stationId}`
        } else {
            targetUrl = `uploadCollection?frequency=${frequency}&year=${data?.year ? data?.year : year}&period=${period}&projectId=${projectId}&category=${category?.toUpperCase()}&vendorId=${vendorId ? vendorId : data?.vendorId ? data?.vendorId : ''}`
        }
        await fetchWrapper(`${API_URL}/document/${targetUrl}`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    toast.success('Document Uploaded successfully!')
                    loadDocumentData();
                }
            })
            .catch((error) => {
                if (error.status === 200) {
                    toast.success('Document Uploaded successfully!')
                    allFiles.forEach(f => f.remove())
                    loadDocumentData();
                }
                if (error.status == 412) {
                    toast.error('File with the same name already exists')
                }
            })
    }

    const handleChange = async (files, allFiles) => {
        let isSameFile = false;
        let tempData = new FormData();
        if (files?.length > 1) {
            files?.map((item, index) => {
                tempData.append('files', item?.file);
            })
            isSameFile = files?.forEach(ele => tableData?.some(item => item?.uploadedFileName === ele?.file?.name)) ? true : false;
        } else if (files?.length === 1) {
            tempData.append('files', files[0]?.file)
            isSameFile = tableData?.some((ele) => ele?.uploadedFileName === files[0]?.file?.name) ? true : false;
        }
        if (isSameFile) {
            Swal.fire({
                title: 'Warning',
                text: `You already have the file with same name. Do you want to replace this?`,
                showConfirmButton: true,
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await savingFile(tempData, allFiles);
                }
                if (result.isDismissed) {
                    onClose()
                }
            })
        } else {
            await savingFile(tempData, allFiles);
        }
    }

    return (
        <Fragment>
            <Modal isOpen={open} size="lg" centered>
                <ModalHeader>
                    <H3 attrH3={{ className: 'modal-title-color' }}>Evidence Upload
                        <X className="x-button" onClick={onClose} />
                    </H3>
                </ModalHeader>
                <ModalBody>
                    {
                        historyState === 0 ? <div>
                            <div className="d-flex justify-between">
                                {
                                    ['plastic', 'solar']?.includes(category) && <div>
                                        {vendorId && <div>Vendor Name: {vendorName}</div>}
                                        <div>Period: {data?.frequency === 'DAILY' ? moment(data?.date).format('DD MMM, YYYY') : data?.period}</div>
                                    </div>
                                }
                                {
                                    category === 'ev' && <div>
                                        {data?.chargerId && <div>Charger ID: {data?.chargerId}</div>}
                                        <div>Station Name: {data?.name}</div>
                                    </div>
                                }
                            </div>
                            <Row>
                                <Col lg='12'>
                                    <div className="waste-collection mtop-1">
                                        <Dropzone
                                            getUploadParams={getUploadParams}
                                            onSubmit={handleChange}
                                            maxSizeBytes={10485760}
                                            accept='.pdf, .jpeg, .jpg, .png'
                                            maxFiles={30}
                                            canCancel={true}
                                            canRemove={true}
                                            canRestart={true}
                                            multiple={true}
                                            inputContent="Drag and Drop Files"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='12'>
                                    <div className="waste-collection-upload">
                                        <div className="ag-theme-material ag-style mtop-2" style={{ width: '100%' }}>
                                            <AgGridTable
                                                columnData={columnsData}
                                                tableData={tableData}
                                                agRef={gridRef}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div> : <></>
                    }
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default MonitoringDocUpload;